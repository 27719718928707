.info_banner{
    width: 290px;
    max-width: 80%;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fbfbfb;
    padding: .5rem;
    border-radius: 5px;
    color: black;
    font-size: .8rem;
    text-align: center;
}

.info_banner a{
    text-decoration: underline;
    color: var(--main-color);
}

.info_banner a:first-of-type {
    float: left;
    font-weight: bold;
}

.hide_banner{
    float: right;
    color: darkgray !important;
}