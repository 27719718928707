@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai+Looped&display=swap');

/* contain overscroll behaviour only in vertical direction */
body {
  overscroll-behavior-y: contain;
}

body{
  background-color: #121212;
  color: white;
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}

:root{
  --max-width: 700px;
}

#root{
  max-width: var(--max-width);
  margin: auto;
  box-shadow: -1px 0px 25px #883227;
  position: relative;
}



button, input{
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

button:disabled{
  color: gray;
  cursor: not-allowed;
}

.btn_reload{
  background: none;
  border: none;
  color: white;
  font-size: .9rem;
  display: block;
  margin: auto;
}

.btn_reload > small{
  display: block;
}

.btn_follow{
  position: absolute;
  top: -25px;
  left: 0;
  font-weight: bold;
  color: lightgray;
  background: none;
  border-color: lightgray;
  border: solid 2px;
  border-radius: 5px;
  font-size: .8rem;
}

::selection {
  background: #1db954; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #1db954; /* Gecko Browsers */
}

.info_btn{
  border: none;
  background: rgba(0,0,0,.85);
  font-weight: bold;
  font-size: .8rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: white;
}

.bg{
  height: 300px;
  background-image: url(./assets/jose.jpg);
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: -50px; 
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(.3);
}

.scrollable{
  padding: 1rem;
  padding-bottom: 100px;
}

button{
  cursor: pointer;
}

.header{
  height: 0;
  position: relative;
  /* min-height: 100px; */
}

.header .title{
  font-size: 2.5rem;
  transform: translateY(-105px);
}

.btnPlayAll{
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #1db954;
  border: none;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 1rem;
  float: right;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnPlayAll span{
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.track_list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.track_list > li{
  padding: .5rem 0;
  margin: 0.25rem 0;
}

.track_list.loading{
  animation-name: load;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-duration: 2s;
}

.track_list.loading > li{
  display: flex;
}

.track_duration, .streams{
  opacity: 1;
  transition: all .5s ease;
}

.streams{
  position: absolute;
  left: 0;
  bottom: 0;
}

.hidden{
  /* transition: all 0s ease; */
  opacity: 0;
}

.l1, .l2 > span{
  background-color: darkgray;
  display: block;
  border-radius: 2.5px;
}

.l2{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 40px;
  width: 250px;
}


.l2 span:first-child{
  width: 70%;
  height: 1rem;
}
.l2 span:last-child{
  width: 40%;
  height: 1rem;
  margin-top: .5rem;
}

@keyframes load{
  0%{
    opacity: 0.25;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: .25;
  }
}

.track_wrapper{
  display: flex;
  justify-content: space-between;
}

.track_wrapper .clickable{
  display: flex;
  max-width: 200px;
}

.track_wrapper.new_release{
  padding: .5rem;
}

.track_wrapper.new_release .img{
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 0;
}

.track_list > li:hover{
  background-color: #5a5a5a;
  border-radius: 5px;
}


li:hover .track_nr_hidden{
  display: inherit;
}

li:hover button.track_nr_hidden{
  display: flex;
  align-items: center;
}

li:hover .track_nr_visible, li:hover .play_animation{
  display: none;
}

.track_nr_hidden{
  display: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: white;
}

.track_wrapper > div{
  display: flex;
}

.track_nr{
  font-size: .8rem;
  align-self: center;
  margin-left: 1rem;
  margin-right: .5rem;
  display: block;
  width: 15px;
}

.img{
  width: 45px;
  height: 45px;
  margin: 0 1rem;
}

.track_title{
  align-self: flex-start;
  display: block;
  /* max-width: 170px; */
  white-space: nowrap;
  /* overflow: hidden; */
  position: relative;
}

.track_title.expl::after{
  content: "explicit";
  background: darkgray;
  color: lightgray;
  position: absolute;
  top: 5px;
  right: -55px;
  z-index: 100;
  width: 45px;
  text-align: center;
  font-size: .7rem;
  font-weight: bold;
  border-radius: 5px;
}

.track_wrapper.new_release .track_title{
  max-width: unset;
}

.track_title > small{
  font-size: .7em;
  color: gray;
  display: block;
}

.track_ctx{
  border: none;
  background: none;
  color: gray;
  font-size: 1.5rem;
  font-weight: bold;
  transform: rotate(90deg);
}

.expand_tracklist{
  border: none;
  background: none;
  color: gray;
  font-weight: bold;
  font-size: .7rem;
  padding: .5rem;
  margin: auto;
  display: block;
  

}

.current_track{
  position: fixed;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #181818;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.current_track img{
  width: 60px;
  height: 60px;
  border: solid gray 1px;
  margin: 0;
}

.current_track .left{
  display: flex;
  align-items: center;
}

.current_track_title{
  margin-left: .5rem;
  display: block;
  max-width: 40vw;
  white-space: nowrap;
  overflow: hidden;
}

.current_track_art{
  color: gray;
  font-size: .7em;
  display: block;
  margin-left: .5rem;
}

.current_track_btn{
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chromecast{
  position: absolute;
  left: 0;
  background-color: var(--main-color);
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: .7rem;
  color: white;
  width: 100%;
  top: -35px;
  font-weight: bold;
}

.btn_disconnect{
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  font-size: .8rem;
}


.overlay .chromecast{
  top: unset;
  bottom: 0;
}


 /* measure text-width */
#Test
{
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap; /* Thanks to Herb Caudill comment */
}


.move_left{
  animation-name: move_left;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  animation-timing-function: ease-in;
  display: block;
}

@keyframes move_left {
  0%{
    transform: translateX(0);
  } 
  25%{
    transform: translateX(0);
  }  
  90%{
    transform: translateX(-50%);
  }
  100%{
    transform: translateX(-50%);
  }
}


.progress{
  width: 100%;
  height: 3px;
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 30;
  background-color: darkgray;
}

.progress > div{
  background: white;
  height: 3px;
  width: 0;
}

.info{
  background-image: url(./assets/jose3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
  display: flex;
  align-items: flex-end;
  filter: grayscale(.3);
}

.info p{
  font-size: .8rem;
  padding: 1rem;
  margin: 0;
  background-color: rgba(0,0,0,.3)
}

.bmab{
  margin-top: 50px;
  text-align: center;
}

.bmab small{
  font-weight: bold;
}

.error{
  position: fixed;
  bottom: 75px;
  left: 50%; 
  transform: translateX(-50%);
  background-color: #e61b1b;
  /* max-width: 70%; */
  max-width: 420px;
  border-radius: 5px;
  padding: 1rem;
  font-size: .7rem;
  font-weight: bold;
}

.error > h3{
  font-size: 1.4rem;
  margin: 0;
}

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.85);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.overlay > .overlay_inner{
  padding-top: 100px;
  padding-bottom: 50px;
  max-height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.overlay.ctx{
  z-index: 101;
}

.lg_img{
  border: solid 1px gray;
  width: 150px;
  height: 150px;
}

.overlay ul{
  list-style: none;
  margin: 0;
  padding: 0;
  width: 70%;
  /* margin-bottom: 150px; */
}

.overlay ul li{
  font-weight: bold;
  margin: 1rem 0;
}

.overlay h2{
  margin-bottom: 0;
}
.overlay h3{
  margin-bottom: 0;
  margin-top: 0;
  font-weight: normal;
  color: lightgray;
}

._streams{
  display: flex;
  justify-content: space-between;
  width: 80%;
  font-weight: bold;
  margin-bottom: 185px;
}

.controls{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.controls button{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: white;
  font-size: 1.2rem;
  text-align: center;
  border: none;
  margin: 0 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls button:nth-child(1), .controls button:nth-child(3){
  font-size: 2rem;
}

.settings{
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}

.playbackRate{
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  width: 65px;
  align-items: center;
  justify-content: center;
}

a{
  color: white;
  text-decoration: none;
}
a:visited, a:hover{
  text-decoration: none;
  color: white;
}

.close_overlay{
  position: absolute;
  top: 1.5rem; 
  left: 1.5rem;
  background: none;
  border: none;
  color: lightgray;
  font-weight: bold;
  font-size: 1.1rem;
}

.overlay .track_ctx{
  position: absolute;
  top: 1.25rem; 
  right: 1.25rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: lightgray;
}

.play_animation{
  width: 30px;
  margin-left: -5px;
}

.play_animation span{
  display: inline-block;
  width: 3px; 
  height: 15px;
  margin: 0 1px;
  background-color: #1db954;
  animation-name: playing;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.play_animation span:nth-child(1){
  animation-delay: .25s;
}
.play_animation span:nth-child(2){
  animation-delay: .5s;
}
.play_animation span:nth-child(3){
  animation-delay: .75s;
}

@keyframes playing{
  from{
    height: 0;
  }
  to{
    height: 15px;
  }
}

/* range slider */

input{
  background: white;
  border: solid 1px lightgray;
  border-radius: 8px;
  height: 7px;
  width: 356px;
  max-width: 90%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-thumb{
  border: 1px solid #CCCCCC;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type=range]::-moz-range-thumb, input[type=range]::-ms-thumb {
  border: 1px solid #CCCCCC;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
}

.time{
  font-weight: bold;
  margin-top: 1rem;
  display: block;
}

input[type=range]{
  margin-top: 1rem;
}

/* switch */

.toggle{
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 1.5rem;
}

.toggle label{
  display: block;
  font-weight: bold;
}

.xlg_img{
  width: 200px;
  height: 200px;
}

.img_container{
  border-radius: 50%;
  border: solid 5px rgba(0,0,0,1);
  position: absolute;
  width: 200px;
  height: 200px;
  overflow: hidden;
  cursor: pointer;
  top: 180px;
}

.img_container.spin{
  transition: all .35s linear;
  /* animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards; */
}

:root {
  --spin-border-color: rgba(0,0,0,.4);
  --main-color: #1db954;
}

.inner{
  display: none;
}

.img_container.spin > .inner{
  display: block;
  position: absolute;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: solid 1px var(--spin-border-color);
}

.inner::before{
  content: "";
  position: absolute;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: solid 1px var(--spin-border-color);
}

.inner::after{
  content: "";
  position: absolute;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: solid 1px var(--spin-border-color);
}

.img_container::before{
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: solid 1px var(--spin-border-color);
}

.img_container::after{
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(0,0,0,1);
  z-index: 100;
}

@keyframes spin {
   0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
  
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #1db954;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1db954;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.rotate{
  animation-name: rotate;
  animation-duration: .5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  color: var(--main-color);
}

@keyframes rotate{
  from{
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
}

@media only screen and (max-height: 670px){
  .overlay > .overlay_inner{
    padding-top: 40px;
  }

  .img_container{
    width: 130px;
    height: 130px;
  }

  ._streams{
    /* display: none; */
    margin-bottom: 130px;
  }
  .img_container{
    top: 140px;
  }

  .img_container .xlg_img{
    width: 140px;
    height: 140px;
  }
}


/*  dot loading  */

.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--main-color);
  color: var(--main-color);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -7.5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--main-color);
  color: var(--main-color);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 7.5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--main-color);
  color: var(--main-color);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--main-color);
  }
  50%,
  100% {
    background-color: lightgray;
  }
}


@media only screen and (min-width: 670px){
  .error{
    bottom: unset;
    top: 50px;
  }

  
  #root{
    animation-name: pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: paused;
  }

  @keyframes pulse{
    0%{
      box-shadow: -1px 0px 35px #883227;
    }
    50%{
      box-shadow: -1px 0px 35px gray;
    }
    100%{
      box-shadow: -1px 0px 35px #883227;
    }
  }

}


