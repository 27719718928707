.upload label{
    display: block;
}

.upload input[type="file"]{
    display: none;
}

.upload input{
    height: 30px;
}

fieldset{
    border: none;
}



.upload button{
    background: none;
    border: solid 2px #1db954;
    border-radius: 5px;
    color: #1db954;
    font-weight: bold;
}

.upload button.ico{
    border: none;
}

.upload.err  button{
    color: #e61b1b;
    border-color: #e61b1b;
}